// REMIX HMR BEGIN
import * as __hmr__ from "remix:hmr";
if (import.meta) {
import.meta.hot = __hmr__.createHotContext(
//@ts-expect-error
"app/routes/_index.tsx"
);
import.meta.hot.lastModified = "1720660792844.5044";
}
// REMIX HMR END

export { default, loader } from "./$.jsx";
